import gsap from "gsap";

// Copies the email variable to clipboard
function copyToClipboard(text) {
  // var dummy = document.createElement("input");

  // dummy.style.position = "fixed";
  // dummy.style.top = 0;
  // dummy.style.left = 0;

  // document.body.appendChild(dummy);
  // dummy.setAttribute("value", text);
  // dummy.select();
  // document.execCommand("copy");
  // document.body.removeChild(dummy);

  navigator.clipboard.writeText(text);
}

let isAnimating = false;

const animateIn = (copyTextEl, iteration) => {
  isAnimating = true;

  gsap.fromTo(
    copyTextEl,
    {
      opacity: 0,
      x: "-50%",
      y: 10,
      rotate: 0,
    },
    {
      opacity: 1,
      x: "-50%",
      y: 0,
      rotate: iteration % 2 ? 9 + "deg" : -9 + "deg",
      duration: 0.15,
      onComplete: () => {
        if (window.matchMedia("(hover:none)").matches) {
          gsap.delayedCall(1.5, animateOut, [copyTextEl, iteration]);
        }
      },
    }
  );
};

const animateOut = (copyTextEl, iteration) => {
  gsap.to(copyTextEl, {
    opacity: 0,
    y: -10,
    duration: 0.15,
    rotate: iteration % 2 ? -9 + "deg" : 9 + "deg",
    onComplete: () => {
      isAnimating = false;
    },
  });
};

export default () => {
  document.querySelectorAll(".-copy-email").forEach((emailButton) => {
    const copyText = emailButton.querySelector(".-copy-email__text");

    const buttonInner = emailButton.querySelector(".-copy-email__text__inner");

    let timesHovered = 0;

    let timesClicked = 0;

    emailButton.addEventListener("click", () => {
      copyToClipboard("hello@dau.lt");

      timesClicked += 1;

      // touch screen

      if (window.matchMedia("(hover:none)").matches) {
        buttonInner.querySelectorAll("div").forEach((el) => {
          el.innerHTML = "copied!";
        });

        if (!isAnimating) {
          animateIn(copyText, timesClicked);
        }
      }

      gsap.fromTo(
        buttonInner,
        {
          y: 0,
        },
        {
          y: "-50%",
          duration: 0.15,
          onComplete: () => {
            if (!window.matchMedia("(hover:none)").matches) {
              buttonInner.style.width = buttonInner.offsetWidth + "px";
            }

            buttonInner.querySelectorAll("div").forEach((el) => {
              el.innerHTML = "copied!";
            });

            buttonInner.style.transform = "translateY(0)";
          },
        }
      );
    });

    emailButton.addEventListener("mouseenter", () => {
      timesHovered += 1;

      if (!isAnimating) {
        animateIn(copyText, timesHovered);
      }

      buttonInner.querySelectorAll("div")[0].innerHTML = "click to copy";
      buttonInner.style.width = "auto";
    });

    emailButton.addEventListener("mouseleave", () => {
      animateOut(copyText, timesHovered);
    });
  });
};
