import "../../style.css";

import Animation from "./modules/animation";

import Footer from "./modules/footer-animation";

import Form from "./modules/form";

import CopyEmail from "./modules/copy-email";

Animation();

Footer();

Form();

CopyEmail();

const videos = document.querySelectorAll(".project-link video");

if (window.matchMedia("(max-width: 60em) and (hover:none)").matches) {
  videos.forEach((video) => {
    video.setAttribute("autoplay", "");
    video.play();
  });
}

document.querySelectorAll(".project-link").forEach((link) => {
  const video = link.querySelector("video");

  link.addEventListener("mouseenter", () => {
    video.play();
  });

  link.addEventListener("mouseleave", () => {
    video.pause();
  });
});

// dynamic textarea resize

if (document.querySelector(".dynamic-textarea") != null) {
  var observe;

  if (window.attachEvent) {
    observe = function (element, event, handler) {
      element.attachEvent("on" + event, () => {
        handler(element);
      });
    };
  } else {
    observe = function (element, event, handler) {
      element.addEventListener(event, () => {
        handler(element);
      });
    };
  }

  function resize(el) {
    el.style.height = "auto";
    el.style.height = el.scrollHeight + 0 + "px";
  }

  function delayedResize(el) {
    window.setTimeout(resize(el), 0);
  }

  document.querySelectorAll(".dynamic-textarea").forEach((item) => {
    observe(item, "change", resize);
    observe(item, "cut", delayedResize);
    observe(item, "paste", delayedResize);
    observe(item, "drop", delayedResize);
    observe(item, "keyup", delayedResize);
    observe(item, "keydown", delayedResize);
  });
}
