import gsap from "gsap";

export default () => {
  const footerTl = gsap.timeline({ paused: true, repeat: 1000 });

  let currentStep = 0;

  let totalSteps = document.querySelectorAll(".rotating-words__inner div").length;

  let stepLength = 100 / totalSteps;

  document.querySelectorAll(".footer-svg-container path").forEach((path, index) => {
    let pathLength = path.getTotalLength();

    path.style.strokeDasharray = pathLength;

    footerTl.fromTo(
      path,
      { opacity: 0 },
      {
        opacity: 1,
        duration: 0.001,
      }
    );

    footerTl.fromTo(path, { strokeDashoffset: pathLength }, { strokeDashoffset: 0, duration: 3 });

    footerTl.to(path, {
      opacity: 0,
      duration: 0.2,
      onStart: () => {
        currentStep += 1;

        if (currentStep == totalSteps) {
          currentStep = 0;
        }

        gsap.to(".rotating-words__inner", {
          y: -stepLength * currentStep + "%",
          duration: 0.5,
          ease: "power1.inOut",
          onComplete: () => {
            if (currentStep == totalSteps - 1) {
              currentStep = 0;
              gsap.to(".rotating-words__inner", { y: 0, duration: 0 });
            }
          },
        });
      },
    });

    footerTl.fromTo(path, { opacity: 1 }, { opacity: 0, duration: 0.001 });
  });

  footerTl.play();
};
