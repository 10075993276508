import gsap from "gsap";

export default () => {
  const heroTl = gsap.timeline({ paused: true, repeat: 1000 });

  document.querySelectorAll(".hero__svg-container path").forEach((path, index) => {
    let pathLength = path.getTotalLength();

    path.style.strokeDasharray = pathLength;

    heroTl.fromTo(path, { opacity: 0 }, { opacity: 1, duration: 0.001 });

    heroTl.fromTo(path, { strokeDashoffset: pathLength }, { strokeDashoffset: 0, duration: 3 });

    heroTl.fromTo(path, { opacity: 1 }, { opacity: 0, duration: 0.2 });
  });

  heroTl.play();
};
