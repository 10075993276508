import gsap, { Power3 } from "gsap";

import heroAnimation from "./hero-animation";

export default () => {
  let delay = 0.5;

  document.querySelector(".content").removeAttribute("style");

  document.querySelector(".hero__svg-container").style.opacity = 0;

  gsap.fromTo(".nav__left", { opacity: 0, y: 10 }, { opacity: 1, y: 0, duration: 0.5, delay: delay });

  gsap.fromTo(".nav__right", { opacity: 0, y: 10 }, { opacity: 1, y: 0, duration: 0.5, delay: delay + 0.1 });

  gsap.to(".nav__divider", { scaleX: 1, duration: 1.5, delay: delay + 0.2, ease: Power3.easeInOut });

  gsap.fromTo(".hero__overlay", { scaleY: 0 }, { scaleY: 1, duration: 0.75, delay: delay + 0.3 });

  gsap.to(".hero__overlay", { borderRadius: 10, duration: 0.5, delay: delay + 0.4 });

  gsap.to(".hero .subheading__line", { scaleX: 1, duration: 1.5, delay: delay + 0.3, ease: Power3.easeInOut });

  gsap.to(".hero .subheading__text-container__inner", { y: 0, duration: 0.75, delay: delay + 0.8, ease: Power3.easeInOut });

  gsap.fromTo(".hero h1", { y: 10, opacity: 0 }, { y: 0, opacity: 1, delay: delay + 1, duration: 0.75 });

  gsap.fromTo(
    ".hero li",
    { x: 10, opacity: 0 },
    {
      opacity: 1,
      x: 0,
      duration: 0.75,
      stagger: (i) => {
        return delay + 1.2 + i * 0.1 * 2;
      },
      onComplete: () => {
        document.querySelector(".hero__svg-container").style.opacity = 1;
        heroAnimation();

        // init on scroll check wsup

        //window height offset until active class is added
        var offset = window.innerHeight * 0.75;

        var osActive = function () {
          for (var i = 0; i < document.querySelectorAll(".e-in").length; i++) {
            var el = document.querySelectorAll(".e-in")[i];

            if (el.getBoundingClientRect().top < offset) {
              if (!el.classList.contains("active")) {
                el.classList.add("active");
              }
            } else {
              // el.classList.remove("active");
            }
          }
        };

        if (document.querySelectorAll(".e-in").length > 0) {
          window.addEventListener("load", function () {
            osActive();
          });

          window.addEventListener("scroll", function () {
            osActive();
          });

          osActive();
        }
      },
    }
  );

  document.querySelectorAll(".hero li path").forEach((path, i) => {
    let pathLength = path.getTotalLength();

    path.style.strokeDasharray = pathLength;

    gsap.fromTo(path, { strokeDashoffset: pathLength }, { strokeDashoffset: 0, duration: 0.75, delay: delay + 2 + i * 0.1 * 2 });
  });
};
