import gsap from "gsap";

const validateEmail = (email) => {
  return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
};

const textInput = document.querySelector("textarea");
const emailInput = document.querySelector("input[type=email]");

let overlay = document.querySelector(".form__submit-overlay");
let iconsContainer = document.querySelector(".form__submit-overlay__icons");
let messageContainer = document.querySelector(".form__submit-message");
let messageInnerContainer = document.querySelector(".form__submit-message__inner");

const overlayIn = () => {
  gsap.fromTo(
    overlay,
    { y: "100%" },
    {
      y: 0,
      duration: 0.5,
      onComplete: () => {
        emailInput.value = "";
        textInput.value = "";
      },
    }
  );

  iconsTl.restart();

  gsap.fromTo(iconsContainer, { y: 10, opacity: 0 }, { y: 0, opacity: 1, duration: 0.5, delay: 0.3 });

  gsap.fromTo(messageContainer, { y: 10, opacity: 0 }, { y: 0, opacity: 1, duration: 0.5, delay: 0.4 });

  gsap.fromTo(messageInnerContainer, { y: 0 }, { y: "-50%", duration: 0.3, delay: 1.5 });
};

const overlayOut = () => {
  gsap.to(iconsContainer, { y: -10, opacity: 0, duration: 0.5, delay: 2 });

  gsap.to(messageContainer, { y: -10, opacity: 0, duration: 0.5, delay: 2.1 });

  gsap.to(overlay, {
    y: "-100%",
    duration: 0.5,
    delay: 2.3,
    onComplete: () => {
      iconsTl.pause();
    },
  });
};

const validateForm = () => {
  let emailValid = 0;
  let textValid = 0;

  // validate email

  if (validateEmail(emailInput.value)) {
    emailValid = 1;
    emailInput.classList.remove("error");
  } else {
    emailValid = 0;
    emailInput.classList.add("error");
  }

  // textarea length
  if (textInput.value.length == 0) {
    textValid = 0;
    textInput.classList.add("error");
  } else {
    textInput.classList.remove("error");
    textValid = 1;
  }

  return textValid + emailValid === 2;
};

const icons = document.querySelectorAll(".form__submit-overlay__icons i");

const iconsTl = new gsap.timeline({ paused: true, repeat: 10000 });

icons.forEach((icon) => {
  iconsTl.to(icon, {
    display: "inline",
    duration: 0.4,
    onComplete: () => {
      icon.style.display = "none";
    },
  });
});

export default () => {
  document.querySelector(".form-submit").addEventListener("click", (e) => {
    e.preventDefault();

    if (validateForm()) {
      const data = { email: emailInput.value, message: textInput.value };

      overlayIn();

      document.querySelectorAll(".form__submit-message__inner div")[1].innerText = "Sent!";

      fetch("https://formspree.io/f/mbjwwwbr", {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          overlayOut();
        })
        .catch((error) => {
          document.querySelectorAll(".form__submit-message__inner div")[1].innerText = "Whoops! Error Occured";
          // console.error("Error:", error);
          overlayOut();
        });
    }
  });
};
